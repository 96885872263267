import {
  h,
  type DefineComponent,
  type FunctionalComponent,
} from 'vue';
import type {
  IconSet,
  IconProps,
} from 'vuetify';
import passwordHideIcon from '~/assets/images/general/icons/password-hide.svg?component';
import passwordShowIcon from '~/assets/images/general/icons/password-show.svg?component';
import calendarIcon from '~/assets/images/general/icons/calendar.svg?component';
import tooltipIconActive from '~/assets/images/general/icons/info-active.svg?component';
import tooltipIcon from '~/assets/images/general/icons/info.svg?component';
import selectIconActive from '~/assets/images/general/icons/select-icon-active.svg?component';
import popularIconActive from '~/assets/images/game/icons/popular-active.svg?component';
import tournamentsIconActive from '~/assets/images/game/icons/tournaments-active.svg?component';
import clockIcon from '~/assets/images/general/icons/clock.svg?component';
import printIcon from '~/assets/images/general/icons/print.svg?component';
import downloadIcon from '~/assets/images/general/icons/download.svg?component';

const customSvgNameToComponent: Record<string, DefineComponent | FunctionalComponent> = {
  passwordHideIcon,
  passwordShowIcon,
  calendarIcon,
  tooltipIconActive,
  tooltipIcon,
  selectIconActive,
  popularIconActive,
  tournamentsIconActive,
  clockIcon,
  printIcon,
  downloadIcon,
};

const customSVGs: IconSet = {
  component: (props: IconProps) => h(
    props.tag,
    [
      h(customSvgNameToComponent[props.icon as string],
        { class: 'v-icon__svg' }),
    ],
  ),
};

export { customSVGs };
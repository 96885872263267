import {
  APP_RUN_MODES,
  PAGES_WITH_REFERRER_PATH,
} from '~/constants/general';
import { useAuthStore } from '~/stores/auth';
import { useGlobalsStore } from '~/stores/globals';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) {
    return;
  }

  if (process.env.NODE_ENV === APP_RUN_MODES.production) {
    const { getUserData } = useAuthStore();
    const { sendReferralLink } = useGlobalsStore();
    const referrerAz = decodeURIComponent(localStorage?.getItem(LOCAL_STORAGE.referrerAz) ?? '');

    if (!getUserData.loggedIn && referrerAz && !to.path.includes(PAGES_WITH_REFERRER_PATH.registration)) {
      await sendReferralLink(referrerAz);
    }
  }
});

/**
 * Nuxt plugin to ensure the active language cookie is set.
 *
 * This plugin checks if the 'activeLanguage' cookie is set. If the cookie is not set
 * it initializes the cookie with a specific default language 'ru'. This ensures that
 * the application uses 'ru' as the default language instead of relying on the system
 * default language settings.
 *
 */
export default defineNuxtPlugin(() => {
  const activeLanguageCookie = useCookie('activeLanguage');

  if (!activeLanguageCookie.value) {
    activeLanguageCookie.value = 'ru';
  }
});

/**
 * Nuxt Plugin to log SSR requests conditionally based on the environment configuration.
 *
 * This plugin provides a logging mechanism for SSR requests, allowing to track request details
 * during server-side rendering. The logging is controlled
 * by an environment variable ssrFetchLoggingEnabled from runtimeConfig, ensuring that SSR request
 * logs are generated only when logging is enabled in the current environment.
 */
import type { RequestLoggerParams } from '~/types/plugins/ssrRequestsLogger';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  nuxtApp.provide('ssrRequestLogger', (
    {
      url,
      startTime,
      success,
      result,
    }: RequestLoggerParams,
  ) => {
    const endTime = Date.now();
    const duration = endTime - startTime;
    const logData = {
      url,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      duration: `${duration}ms`,
      success,
      result: JSON.stringify(result),
    };

    if (runtimeConfig.public.ssrFetchLoggingEnabled) {
      console.info('*******************SSR REQUEST*******************');
      console.info(JSON.stringify(logData, null, 2));
    }
  });
});

import type { UserDataSqwonChat } from '~/types/general/user';

export const useSqwonChat = (userChatData: UserDataSqwonChat, isReInit: boolean) => {
  if (isReInit) {

    //Removing already mounted elements and reinitialization
    const sqwonchatConfigScript = document.getElementById('sqwonchatConfig');

    sqwonchatConfigScript?.parentNode?.removeChild(sqwonchatConfigScript);
    const sqwonchatContainer = document.querySelector('div.sqwonchat-container');

    sqwonchatContainer?.parentNode?.removeChild(sqwonchatContainer);
  }
  sqwonChatInitialization(userChatData);
};

const sqwonChatInitialization = (userDataForInitialization: UserDataSqwonChat) => {
  const sqwonchatConfigScriptId = 'sqwonchatConfig';
  const sqwonchatInitScriptId = 'sqwonchatInit';

  // Create and add the config script
  const configScriptElement = document.createElement('script');

  configScriptElement.id = sqwonchatConfigScriptId;
  configScriptElement.innerHTML = `window.SqwonchatConfig = ${JSON.stringify(userDataForInitialization)};`;
  document.body.appendChild(configScriptElement);

  // Create and add the chat widget script
  const chatWidgetScriptElement = document.createElement('script');

  chatWidgetScriptElement.id = sqwonchatInitScriptId;
  chatWidgetScriptElement.src = useRuntimeConfig()?.public?.sqwonChatUrl;
  document.body.appendChild(chatWidgetScriptElement);
};

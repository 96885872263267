export default defineNuxtRouteMiddleware((to) => {
  const isMaintenanceModeOn = useRuntimeConfig()?.public?.maintenance;

  if (isMaintenanceModeOn && (to.path !== '/maintenance' && to.path !== '/en/maintenance')) {
    return navigateTo('/maintenance');
  }
  if (!isMaintenanceModeOn && (to.path === '/maintenance' || to.path === '/en/maintenance' )) {
    return navigateTo('/');
  }
});

import {
  defineStore,
  storeToRefs,
} from 'pinia';
import type { GlobalsStoreState } from '~/types/stores/globals';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { useAuthStore } from '~/stores/auth';
import { useCabinetStore } from '~/stores/cabinet';
import type {
  FetchCurrenciesResponse,
  AcceptTermsRequest,
} from '~/types/general/globals';
import type { NotificationsItem } from '~/types/components/General/Notifications';
import type { CashConfigType } from '~/types/general/cash';

export const useGlobalsStore = defineStore({
  id: 'globals',
  state: (): GlobalsStoreState => ({
    currencies: [],
    notifications: [],
    cashConfig: {},
    freeSpinsIntervalId: null,
  }),
  getters: {
    getCurrencies: (state) => state.currencies,
    getNotifications: (state) => state.notifications,
    getCashConfig: (state) => state.cashConfig,
   },
  actions: {
    async fetchCurrencies() {
      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.account}/currencies`) as FetchCurrenciesResponse;

        this.currencies = data.return;
      } catch(error) {
        throw error;
      }
    },
    async sendReferralLink(requestRef: string) {
      try {
        await useMyFetch().post(`${API_PREFIX.default}/affiliate/hit`, { query: { requestRef } });
      } catch(error) {
        console.error(error);
      }
    },
    setNotificationsFromStorage(notifications: NotificationsItem[]) {
      this.notifications = notifications;
    },
    setNotification(notification: NotificationsItem) {
      const notificationData = {
        ...notification,
        id: Date.now(),
        showing: true,
      };

      this.notifications.push(notificationData);

      if (notificationData.timeout === -1) {
        const notificationsStorage = useLocalStorageObject.get('j-notifications');

        useLocalStorageObject.set(
          'j-notifications',
          notificationsStorage?.length
            ? [...notificationsStorage, notificationData]
            : [notificationData],
        );
      }
    },
    removeNotification(notification: NotificationsItem) {
      this.notifications = this.notifications.filter((notif) => notif.id !== notification.id);

      if (notification.timeout === -1) {
        let notificationsStorage = useLocalStorageObject.get('j-notifications');

        if (notificationsStorage?.length) {
          notificationsStorage = notificationsStorage.filter((notif: NotificationsItem) => notif.id !== notification.id);
          notificationsStorage.length
            ? useLocalStorageObject.set('j-notifications', notificationsStorage)
            : useLocalStorageObject.remove('j-notifications');
        }
      }
    },
    removeAllNotificationsExceptCookie() {
      this.notifications = this.notifications.filter((notif) => notif.isCookie);

      let notificationsStorage = useLocalStorageObject.get('j-notifications');

      if (notificationsStorage?.length) {
        notificationsStorage = notificationsStorage.filter((notif: NotificationsItem) => notif.isCookie);
          notificationsStorage.length
            ? useLocalStorageObject.set('j-notifications', notificationsStorage)
            : useLocalStorageObject.remove('j-notifications');
      }
    },
    closeNotification(notification: NotificationsItem) {
      const closeIfExists = (notifArr: NotificationsItem[]) => {
        const index = notifArr.findIndex((item) => item.bonusId === notification.bonusId);

        if (index !== -1) {
          notifArr[index].showing = false;
        };
      };

      closeIfExists(this.notifications);

      if (notification.timeout === -1) {
        const notificationsStorage = useLocalStorageObject.get('j-notifications');

        if (notificationsStorage?.length) {
          closeIfExists(notificationsStorage);
          useLocalStorageObject.set('j-notifications', notificationsStorage);
        }
      }
    },
    async acceptTerms() {
      const authStore = useAuthStore();

      try {
        const { data } = await useMyFetch().post(`${API_PREFIX.playerProfile}/accept-terms`) as AcceptTermsRequest;

        if (data.success) {
          authStore.userData.termsAccepted = data.success;
        }
      } catch (error) {
        throw(error);
      }
    },
    showTermsAndConditions() {
      const authStore = useAuthStore();

      authStore.userData.showTermsAndConditions = true;
    },
    /**
     * Polls the API every 2 seconds for up to 10 seconds to check if free spins have been applied.
     * If free spins are detected, it triggers a notification with the relevant details.
     * The polling stops either when free spins are found or after 5 attempts.
     *
     * @param {string} platform - The platform identifier to fetch the free spins count for.
     */
    async startFreeSpinsPolling(platform: string) {
      const { fetchFreeSpinsCount } = useCabinetStore();
      let timesRun = 0;

      if (this.freeSpinsIntervalId) {
        this.clearFreeSpinsInterval();
      }
      this.freeSpinsIntervalId = window.setInterval(async () => {
        timesRun += 1;
        const freeSpinItem = await fetchFreeSpinsCount(platform);

        if (freeSpinItem || timesRun === 5) {
          this.clearFreeSpinsInterval();
        }

        if (freeSpinItem) {
          this.setNotification({
            icon: 'notification-free-spins',
            dictionaryKeyTitle: 'general_free_spins',
            dictionaryKeyText: 'cabinet_balance_promocode_bonus_added_free_spins',
            dictionaryKeyTextProps: {
              count: freeSpinItem.prize,
              name: freeSpinItem.games[0].name,
            },
            timeout: -1,
            isFreeSpins: true,
            bonusId: freeSpinItem.bonus_id,
            buttons: [
              {
                class: 'text-none text-body-1 px-9 px-lg-5 j-btn j-btn--gradient-main',
                dictionaryKey: 'general_play',
                action: {
                  name: 'gameLink',
                  gamePrettyName: freeSpinItem.games[0].prettyName,
                },
              },
            ],
          });
        }
      }, 2000);
    },
    clearFreeSpinsInterval() {
      clearInterval(Number(this.freeSpinsIntervalId));
      this.freeSpinsIntervalId = null;
    },
    async fetchCashConfig(cashierType: string) {
      try {
        const staticStorageUrl = useBaseAppUrl().static;
        const authStore = useAuthStore();
        const { getUserData } = storeToRefs(authStore);

        this.cashConfig = (await useMyFetch().get(
          `${staticStorageUrl}/payment/${cashierType}-config.json?language=${getUserData.value.locale}`,
        )).data as CashConfigType;
      } catch (error) {
        throw(error);
      }
    },
  },
});

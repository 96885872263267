<template>
  <nuxt-layout name="services">
    <transition name="fade">
      <j-loader-indicator v-if="getShowPageLoader" />
    </transition>
    <!--TODO: create separate component with content for each error type-->
    <div
      v-if="isShowPageNotFound"
      class="j-page-error d-flex flex-column mt-6 mt-lg-16 pt-lg-6"
    >
      <div class="overflow-auto">
        <v-img
          :lazy-src="useImgSrc(images['404_desktop'])"
          :src="useImgSrc(images['404_desktop'])"
          alt="404"
          class="ma-auto"
        />
      </div>
      <div class="text-center mt-12 ">
        <general-text
          :text-types="TEXT_TYPES.pageTitle"
          dictionary-key="404_error_page_title"
          class="j-text--white"
        />
      </div>
      <div class="text-center mt-2">
        <general-text
          :text-types="TEXT_TYPES.subTitle"
          dictionary-key="404_error_try_again"
          class="j-text--white"
        />
      </div>
      <div class="text-center mt-10 mb-16">
        <v-btn
          :color="scssVariables.jBtnRegular"
          to="/"
          variant="flat"
          size="large"
          class="text-none text-body-1"
        >
          <general-text
            :text-types="TEXT_TYPES.subTitle"
            dictionary-key="404_error_go_back"
            class="j-text--white"
          />
        </v-btn>
      </div>
    </div>
    <div
      v-else
      :data-build-last-commit-hash="runtimeConfig.public.buildLastCommitHash"
    >
      <h1>
        500 error
      </h1>
    </div>
  </nuxt-layout>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { TEXT_TYPES } from '~/constants/general';
import { useAuthStore } from '~/stores/auth';
import { useLayoutStore } from '~/stores/layout';
import {
  INFO_MESSAGE_PAGE_PARAMS,
  INFO_MESSAGE_PAGE_ROUTE,
} from '~/constants/infoMessages';

const props = defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
});
const runtimeConfig = useRuntimeConfig();
const router = useRouter();
const images = useAssetsImages();
const scssVariables = useScssVariables();
const isShowPageNotFound = computed( () => {
  return props?.error?.statusCode === 404;
});
const isAuthenticationError = computed( () => {
  return props?.error?.statusCode === 401;
});

const layoutStore = useLayoutStore();
const { setPageLoader } = layoutStore;
const { getShowPageLoader } = storeToRefs(layoutStore);

//Chat initialization and connection
const authStore = useAuthStore();
const {
  initChat,
  logout,
} = authStore;

onMounted(async () => {
  if (isAuthenticationError.value) {
    await logout();
    await router.push({ path: `${INFO_MESSAGE_PAGE_ROUTE}/${INFO_MESSAGE_PAGE_PARAMS.sessionExpired}` });
  } else {
    initChat();
    setPageLoader(false);
    if (!isShowPageNotFound.value) {
      throw (toRaw(props?.error));
    }
  }
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.j-page-error{
  height: calc(100vh - 154px);
  max-height: 100%;
}
</style>

import { useLayoutStore } from '~/stores/layout';
export default defineNuxtRouteMiddleware((to, from) => {
  const { setPageLoader } = useLayoutStore();

  if (to.path === from.path && JSON.stringify(to.query) !== JSON.stringify(from.query)) {
    setPageLoader(false);
  } else {
    setPageLoader(true);
  }
});

import type { formats } from '~/types/composables/useImgSrc';

export const useImgSrc = (src: string, format: formats = 'auto') => {
  const img = useImage();

  return img(src
    ? src.replace('/_nuxt/assets/images', '')
    : src, {
    format,
    quality: 80,
  });
};
import { default as constantsFk6ChcYjJBMeta } from "/usr/src/app/pages/about-us/constants.ts?macro=true";
import { default as indexJGIzRwMyDQMeta } from "/usr/src/app/pages/about-us/index.vue?macro=true";
import { default as indexYp76OcCelFMeta } from "/usr/src/app/pages/account-payouts-bonuses/index.vue?macro=true";
import { default as indexiplDru9HBjMeta } from "/usr/src/app/pages/aml-kyc/index.vue?macro=true";
import { default as indexcq83mckYSDMeta } from "/usr/src/app/pages/cabinet/[pageType]/index.vue?macro=true";
import { default as index65eLJVoiNDMeta } from "/usr/src/app/pages/cabinet/index.vue?macro=true";
import { default as _91_46_46_46pageType_93FTwXFhT8ptMeta } from "/usr/src/app/pages/cashier/[...pageType].vue?macro=true";
import { default as indexaKcPkwalLYMeta } from "/usr/src/app/pages/confirmation/index.vue?macro=true";
import { default as indexcO7HcgZp5eMeta } from "/usr/src/app/pages/cookie-policy/index.vue?macro=true";
import { default as indexg9EujNMY3MMeta } from "/usr/src/app/pages/dispute-resolution/index.vue?macro=true";
import { default as indexmD6ticbvmrMeta } from "/usr/src/app/pages/exit-game/index.vue?macro=true";
import { default as indexpbc6sl4EfBMeta } from "/usr/src/app/pages/fairness-rng/index.vue?macro=true";
import { default as indexL9FjPKJ8ZcMeta } from "/usr/src/app/pages/game/[gamePrettyName]/[mode]/index.vue?macro=true";
import { default as _91_46_46_46params_93Fvs88X3OHOMeta } from "/usr/src/app/pages/games/[gameProvider]/[gameCategory]/[...params].vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexuLMX5COnP0Meta } from "/usr/src/app/pages/info-message/[pageType]/index.vue?macro=true";
import { default as indexMqL0VaR3piMeta } from "/usr/src/app/pages/login/index.vue?macro=true";
import { default as indexAPAl2SUOyBMeta } from "/usr/src/app/pages/maintenance/index.vue?macro=true";
import { default as _91_46_46_46params_93mOtjTWyw2tMeta } from "/usr/src/app/pages/new-games/[gameProvider]/[gameCategory]/[...params].vue?macro=true";
import { default as index3SgtOe5439Meta } from "/usr/src/app/pages/news/[id]/index.vue?macro=true";
import { default as index6s0GhshVDKMeta } from "/usr/src/app/pages/news/index.vue?macro=true";
import { default as indexUqi8FpGrndMeta } from "/usr/src/app/pages/privacy-policy/index.vue?macro=true";
import { default as indexljrbI4DpjjMeta } from "/usr/src/app/pages/promo/[pageType]/index.vue?macro=true";
import { default as indexOdpOcVkRRcMeta } from "/usr/src/app/pages/promo/index.vue?macro=true";
import { default as indexrYgLA4WwBfMeta } from "/usr/src/app/pages/registration/index.vue?macro=true";
import { default as index6vu8Qz2W1OMeta } from "/usr/src/app/pages/reset-password-email/index.vue?macro=true";
import { default as constants1vC6JcdsHNMeta } from "/usr/src/app/pages/responsible-gaming/constants.ts?macro=true";
import { default as indexp5lSn75R8gMeta } from "/usr/src/app/pages/responsible-gaming/index.vue?macro=true";
import { default as indexHSrUvLiDWBMeta } from "/usr/src/app/pages/restore/index.vue?macro=true";
import { default as index5IRXlR1H0DMeta } from "/usr/src/app/pages/self-exclusion/index.vue?macro=true";
import { default as constantsEk6wbIzxvMMeta } from "/usr/src/app/pages/terms-and-conditions/constants.ts?macro=true";
import { default as indexpanvQrFwn1Meta } from "/usr/src/app/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexDUxVmewe5aMeta } from "/usr/src/app/pages/tournaments/[pageType]/[id]/index.vue?macro=true";
import { default as indexVMbtDbuKUxMeta } from "/usr/src/app/pages/tournaments/[pageType]/index.vue?macro=true";
import { default as component_45stubuYxk0V0rW5Meta } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuYxk0V0rW5 } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us-constants___ru",
    path: "/about-us/constants",
    component: () => import("/usr/src/app/pages/about-us/constants.ts").then(m => m.default || m)
  },
  {
    name: "about-us-constants___en",
    path: "/en/about-us/constants",
    component: () => import("/usr/src/app/pages/about-us/constants.ts").then(m => m.default || m)
  },
  {
    name: "about-us___ru",
    path: "/about-us",
    component: () => import("/usr/src/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/usr/src/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "account-payouts-bonuses___ru",
    path: "/account-payouts-bonuses",
    component: () => import("/usr/src/app/pages/account-payouts-bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-payouts-bonuses___en",
    path: "/en/account-payouts-bonuses",
    component: () => import("/usr/src/app/pages/account-payouts-bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "aml-kyc___ru",
    path: "/aml-kyc",
    component: () => import("/usr/src/app/pages/aml-kyc/index.vue").then(m => m.default || m)
  },
  {
    name: "aml-kyc___en",
    path: "/en/aml-kyc",
    component: () => import("/usr/src/app/pages/aml-kyc/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-pageType___ru",
    path: "/cabinet/:pageType()",
    meta: indexcq83mckYSDMeta || {},
    component: () => import("/usr/src/app/pages/cabinet/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-pageType___en",
    path: "/en/cabinet/:pageType()",
    meta: indexcq83mckYSDMeta || {},
    component: () => import("/usr/src/app/pages/cabinet/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet___ru",
    path: "/cabinet",
    meta: index65eLJVoiNDMeta || {},
    component: () => import("/usr/src/app/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet___en",
    path: "/en/cabinet",
    meta: index65eLJVoiNDMeta || {},
    component: () => import("/usr/src/app/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: "cashier-pageType___ru",
    path: "/cashier/:pageType(.*)*",
    meta: _91_46_46_46pageType_93FTwXFhT8ptMeta || {},
    component: () => import("/usr/src/app/pages/cashier/[...pageType].vue").then(m => m.default || m)
  },
  {
    name: "cashier-pageType___en",
    path: "/en/cashier/:pageType(.*)*",
    meta: _91_46_46_46pageType_93FTwXFhT8ptMeta || {},
    component: () => import("/usr/src/app/pages/cashier/[...pageType].vue").then(m => m.default || m)
  },
  {
    name: "confirmation___ru",
    path: "/confirmation",
    meta: indexaKcPkwalLYMeta || {},
    component: () => import("/usr/src/app/pages/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "confirmation___en",
    path: "/en/confirmation",
    meta: indexaKcPkwalLYMeta || {},
    component: () => import("/usr/src/app/pages/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___ru",
    path: "/cookie-policy",
    component: () => import("/usr/src/app/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___en",
    path: "/en/cookie-policy",
    component: () => import("/usr/src/app/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "dispute-resolution___ru",
    path: "/dispute-resolution",
    component: () => import("/usr/src/app/pages/dispute-resolution/index.vue").then(m => m.default || m)
  },
  {
    name: "dispute-resolution___en",
    path: "/en/dispute-resolution",
    component: () => import("/usr/src/app/pages/dispute-resolution/index.vue").then(m => m.default || m)
  },
  {
    name: "exit-game___ru",
    path: "/exit-game",
    meta: indexmD6ticbvmrMeta || {},
    component: () => import("/usr/src/app/pages/exit-game/index.vue").then(m => m.default || m)
  },
  {
    name: "exit-game___en",
    path: "/en/exit-game",
    meta: indexmD6ticbvmrMeta || {},
    component: () => import("/usr/src/app/pages/exit-game/index.vue").then(m => m.default || m)
  },
  {
    name: "fairness-rng___ru",
    path: "/fairness-rng",
    component: () => import("/usr/src/app/pages/fairness-rng/index.vue").then(m => m.default || m)
  },
  {
    name: "fairness-rng___en",
    path: "/en/fairness-rng",
    component: () => import("/usr/src/app/pages/fairness-rng/index.vue").then(m => m.default || m)
  },
  {
    name: "game-gamePrettyName-mode___ru",
    path: "/game/:gamePrettyName()/:mode()",
    meta: indexL9FjPKJ8ZcMeta || {},
    component: () => import("/usr/src/app/pages/game/[gamePrettyName]/[mode]/index.vue").then(m => m.default || m)
  },
  {
    name: "game-gamePrettyName-mode___en",
    path: "/en/game/:gamePrettyName()/:mode()",
    meta: indexL9FjPKJ8ZcMeta || {},
    component: () => import("/usr/src/app/pages/game/[gamePrettyName]/[mode]/index.vue").then(m => m.default || m)
  },
  {
    name: "games-gameProvider-gameCategory-params___ru",
    path: "/games/:gameProvider()/:gameCategory()/:params(.*)*",
    meta: _91_46_46_46params_93Fvs88X3OHOMeta || {},
    component: () => import("/usr/src/app/pages/games/[gameProvider]/[gameCategory]/[...params].vue").then(m => m.default || m)
  },
  {
    name: "games-gameProvider-gameCategory-params___en",
    path: "/en/games/:gameProvider()/:gameCategory()/:params(.*)*",
    meta: _91_46_46_46params_93Fvs88X3OHOMeta || {},
    component: () => import("/usr/src/app/pages/games/[gameProvider]/[gameCategory]/[...params].vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-message-pageType___ru",
    path: "/info-message/:pageType()",
    meta: indexuLMX5COnP0Meta || {},
    component: () => import("/usr/src/app/pages/info-message/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "info-message-pageType___en",
    path: "/en/info-message/:pageType()",
    meta: indexuLMX5COnP0Meta || {},
    component: () => import("/usr/src/app/pages/info-message/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ru",
    path: "/login",
    meta: indexMqL0VaR3piMeta || {},
    component: () => import("/usr/src/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexMqL0VaR3piMeta || {},
    component: () => import("/usr/src/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___ru",
    path: "/maintenance",
    meta: indexAPAl2SUOyBMeta || {},
    component: () => import("/usr/src/app/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/en/maintenance",
    meta: indexAPAl2SUOyBMeta || {},
    component: () => import("/usr/src/app/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "new-games-gameProvider-gameCategory-params___ru",
    path: "/new-games/:gameProvider()/:gameCategory()/:params(.*)*",
    meta: _91_46_46_46params_93mOtjTWyw2tMeta || {},
    component: () => import("/usr/src/app/pages/new-games/[gameProvider]/[gameCategory]/[...params].vue").then(m => m.default || m)
  },
  {
    name: "new-games-gameProvider-gameCategory-params___en",
    path: "/en/new-games/:gameProvider()/:gameCategory()/:params(.*)*",
    meta: _91_46_46_46params_93mOtjTWyw2tMeta || {},
    component: () => import("/usr/src/app/pages/new-games/[gameProvider]/[gameCategory]/[...params].vue").then(m => m.default || m)
  },
  {
    name: "news-id___ru",
    path: "/news/:id()",
    meta: index3SgtOe5439Meta || {},
    component: () => import("/usr/src/app/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    meta: index3SgtOe5439Meta || {},
    component: () => import("/usr/src/app/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "news___ru",
    path: "/news",
    component: () => import("/usr/src/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/usr/src/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ru",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-pageType___ru",
    path: "/promo/:pageType()",
    meta: indexljrbI4DpjjMeta || {},
    component: () => import("/usr/src/app/pages/promo/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-pageType___en",
    path: "/en/promo/:pageType()",
    meta: indexljrbI4DpjjMeta || {},
    component: () => import("/usr/src/app/pages/promo/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "promo___ru",
    path: "/promo",
    component: () => import("/usr/src/app/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: "promo___en",
    path: "/en/promo",
    component: () => import("/usr/src/app/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: "registration___ru",
    path: "/registration",
    meta: indexrYgLA4WwBfMeta || {},
    component: () => import("/usr/src/app/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "registration___en",
    path: "/en/registration",
    meta: indexrYgLA4WwBfMeta || {},
    component: () => import("/usr/src/app/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-email___ru",
    path: "/reset-password-email",
    meta: index6vu8Qz2W1OMeta || {},
    component: () => import("/usr/src/app/pages/reset-password-email/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-email___en",
    path: "/en/reset-password-email",
    meta: index6vu8Qz2W1OMeta || {},
    component: () => import("/usr/src/app/pages/reset-password-email/index.vue").then(m => m.default || m)
  },
  {
    name: "responsible-gaming-constants___ru",
    path: "/responsible-gaming/constants",
    component: () => import("/usr/src/app/pages/responsible-gaming/constants.ts").then(m => m.default || m)
  },
  {
    name: "responsible-gaming-constants___en",
    path: "/en/responsible-gaming/constants",
    component: () => import("/usr/src/app/pages/responsible-gaming/constants.ts").then(m => m.default || m)
  },
  {
    name: "responsible-gaming___ru",
    path: "/responsible-gaming",
    component: () => import("/usr/src/app/pages/responsible-gaming/index.vue").then(m => m.default || m)
  },
  {
    name: "responsible-gaming___en",
    path: "/en/responsible-gaming",
    component: () => import("/usr/src/app/pages/responsible-gaming/index.vue").then(m => m.default || m)
  },
  {
    name: "restore___ru",
    path: "/restore",
    meta: indexHSrUvLiDWBMeta || {},
    component: () => import("/usr/src/app/pages/restore/index.vue").then(m => m.default || m)
  },
  {
    name: "restore___en",
    path: "/en/restore",
    meta: indexHSrUvLiDWBMeta || {},
    component: () => import("/usr/src/app/pages/restore/index.vue").then(m => m.default || m)
  },
  {
    name: "self-exclusion___ru",
    path: "/self-exclusion",
    component: () => import("/usr/src/app/pages/self-exclusion/index.vue").then(m => m.default || m)
  },
  {
    name: "self-exclusion___en",
    path: "/en/self-exclusion",
    component: () => import("/usr/src/app/pages/self-exclusion/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-constants___ru",
    path: "/terms-and-conditions/constants",
    component: () => import("/usr/src/app/pages/terms-and-conditions/constants.ts").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-constants___en",
    path: "/en/terms-and-conditions/constants",
    component: () => import("/usr/src/app/pages/terms-and-conditions/constants.ts").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ru",
    path: "/terms-and-conditions",
    component: () => import("/usr/src/app/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/usr/src/app/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-pageType-id___ru",
    path: "/tournaments/:pageType()/:id()",
    meta: indexDUxVmewe5aMeta || {},
    component: () => import("/usr/src/app/pages/tournaments/[pageType]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-pageType-id___en",
    path: "/en/tournaments/:pageType()/:id()",
    meta: indexDUxVmewe5aMeta || {},
    component: () => import("/usr/src/app/pages/tournaments/[pageType]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-pageType___ru",
    path: "/tournaments/:pageType()",
    meta: indexVMbtDbuKUxMeta || {},
    component: () => import("/usr/src/app/pages/tournaments/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-pageType___en",
    path: "/en/tournaments/:pageType()",
    meta: indexVMbtDbuKUxMeta || {},
    component: () => import("/usr/src/app/pages/tournaments/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubuYxk0V0rW5
  }
]
import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';
import { useAuthStore } from '~/stores/auth';
import { PLATFORM } from '~/constants/general';
import type { NotificationsItem } from '~/types/components/General/Notifications';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) {
    return;
  }

  const { getUserData } = useAuthStore();
  const { fetchFreeSpinsCount } = useCabinetStore();
  const { setNotification } = useGlobalsStore();
  const currentPlatform = computed(() => window.innerWidth < 1280
    ? PLATFORM.mobile
    : PLATFORM.desktop,
  );

  if (
    getUserData.loggedIn &&
    !to.path.includes('/game/') &&
    !to.path.includes('/cashier') &&
    (getUserData.locale === 'en'
      ? to.path.includes('/en/')
      : true)
  ) {
    const storageNotifications = useLocalStorageObject.get('j-notifications')  as NotificationsItem[];
    const freeSpinItem = await fetchFreeSpinsCount(currentPlatform.value);

    if (freeSpinItem) {
      const isSameNotification =  storageNotifications?.length
        ? storageNotifications.find((notif) => notif.bonusId === freeSpinItem.bonus_id)
        : false;

      if (!isSameNotification) {
        setNotification({
          icon: 'notification-free-spins',
          dictionaryKeyTitle: 'general_free_spins',
          dictionaryKeyText: 'cabinet_balance_promocode_bonus_added_free_spins',
          dictionaryKeyTextProps: {
            count: freeSpinItem.prize,
            name: freeSpinItem.games[0].name,
          },
          timeout: -1,
          isFreeSpins: true,
          bonusId: freeSpinItem.bonus_id,
          buttons: [
            {
              class: 'text-none text-body-1 px-9 px-lg-5 j-btn j-btn--gradient-main',
              dictionaryKey: 'general_play',
              action: {
                name: 'gameLink',
                gamePrettyName: freeSpinItem.games[0].prettyName,
              },
            },
          ],
        });
      }
    }
  }
});

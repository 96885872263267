import type {
  PagesWithReferrerPath,
  RequiredAuthPagesWithChildren,
} from '~/types/general/globals';
import type { Locales } from '~/types/general/locales';

// TODO: Divide to separate files
export const LOCALES: Locales = {
  en: {
    localeNameMobile: 'English',
    localeNameDesktop: 'En',
  },
  ru: {
    localeNameMobile: 'Russian',
    localeNameDesktop: 'Ru',
  },
};

export const TEXT_TYPES: { [key: string]: string } = {
  pageTitle: 'pageTitle',
  title: 'title',
  subTitle: 'subTitle',
  regular: 'regular',
  itemList: 'itemList',
};

export const PLAYER_STATUS: { [key: string]: string } = {
  new: 'New',
  player: 'Player',
  silver: 'Silver',
  gold: 'Gold',
  vip: 'VIP',
};
export const BALANCE_TYPES: { [key: string]: string } = {
  crypto: 'CRYPTO',
  fiat: 'FIAT',
  fun: 'FUN',
};
export const PLATFORM: { [key: string]: string } = {
  mobile: 'MOBILE',
  desktop: 'DESKTOP',
  xs: 'XS',
};

export const FILTERS = [
  {
    icon: 'all_games',
    text: 'general_all_games',
    category: 'ALL',
  },
  {
    icon: 'slots',
    text: 'general_slots',
    category: 'SLOT',
  },
  {
    icon: 'jackpots',
    text: 'general_jackpots',
    category: 'JACKPOT',
  },
  {
    icon: 'live_games',
    text: 'general_live_games',
    category: 'LIVE',
  },
  {
    icon: 'table_games',
    text: 'general_table_games',
    category: 'TABLE_GAMES',
  },
  {
    icon: 'video_poker',
    text: 'general_video_poker',
    category: 'VIDEO_POKER',
  },
  {
    icon: 'loto',
    text: 'general_lotteries',
    category: 'LOTO',
  },
];

export const REQUIRED_AUTH_PAGES_WITH_CHILDREN: RequiredAuthPagesWithChildren = {
  cabinet: {
    balance: 'balance',
    'personal-data': 'personal-data',
    verification: 'verification',
    'games-history': 'games-history',
    'transaction-history': 'transaction-history',
    'bonus-history': 'bonus-history',
    security: 'security',
  },
  game: {
    real: 'real',
    fun: 'fun',
  },
  cashier: {},
};

export const PAGES_WITH_REFERRER_PATH: PagesWithReferrerPath = {
  login: 'login',
  registration: 'registration',
  restore: 'restore',
};

export const PROMO_CARD_CONTENT_TYPES = {
  image: 'image',
  text: 'general-text',
  title: 'title',
  link: 'link',
  html: 'html',
};

export const PROMOTION_DATA = {
  active: 'ACTIVE',
  cancel: 'CANCEL',
  available: 'AVAILABLE',
  join: 'JOIN',
  regularBonus: 'REGULAR_BONUS',
  confirmEmailText: 'general_confirm_email',
  verifyPhoneText: 'general_verify_phone',
};

export const GAMES_TYPES = {
  all: 'all',
  new: 'new',
  homepageSearch: 'homepage_search',
  popular: 'popular',
};

export const GAME_CATEGORIES = {
  all: 'ALL',
  tableGames: 'TABLE_GAMES',
  live: 'LIVE',
  loto: 'LOTO',
  videoPoker: 'VIDEO_POKER',
  jackpot: 'JACKPOT',
  slot: 'SLOT',
};

export const GAME_PROVIDERS = { all: 'ALL' };

export const GAME_TYPES = { external: 'EXTERNAL' };

export const GAME_LABELS = {
  new: 'NEW',
  hot: 'HOT',
  tournament: 'TOURNAMENT',
};

export const GAME_MIN_BET_DIRECTIONS = {
  all: '',
  asc: 'ASC',
  desc: 'DESC',
};

export const GAMES_SLIDER_CONFIG = {
  desktop: {
    columns: 6,
    columnsTournament: 3,
    columnsTournamentDetails: 5,
    gap: 12,
    allGamesChunkSize: 36,
    newGamesChunkSize: 12,
    tournamentGamesChunkSize: 6,
    tournamentDetailsGamesChunkSize: 15,
  },
  mobile: {
    columns: 3,
    gap: 6,
    allGamesChunkSize: 15,
    newGamesChunkSize: 6,
  },
  xs: {
    columns: 2,
    allGamesChunkSize: 14,
    tournamentGamesChunkSize: 4,
    newGamesChunkSize: 0,
    gap: 0,
  },
};

export const NEWS_SLIDER_CONFIG = {
  desktop: {
    columns: 3,
    gap: 32,
    chunkSize: 3,
  },
  mobile: {
    columns: 1,
    gap: 0,
    chunkSize: 1,
  },
};

export const NEWS_CONTENT_GRID = {
  desktop: {
    columns: 3,
    gap: 32,
  },
  mobile: {
    columns: 2,
    gap: 16,
  },
  xs: { columns: 1 },
};

export const COUNTRIES = [
  {
    code_iso: 20,
    name_short_en: 'Andorra',
    name_short_ru: 'Андорра',
    code_alpha2: 'AD',
    active: true,
    phone_code: 376,
    popular: false,
  },
  {
    code_iso: 660,
    name_short_en: 'Anguilla',
    name_short_ru: 'Ангилья о.',
    code_alpha2: 'AI',
    active: true,
    phone_code: 1264,
    popular: false,
  },
  {
    code_iso: 28,
    name_short_en: 'Antigua and Barbuda',
    name_short_ru: 'Антигуа и Барбуда',
    code_alpha2: 'AG',
    active: true,
    phone_code: 1268,
    popular: false,
  },
  {
    code_iso: 32,
    name_short_en: 'Argentina',
    name_short_ru: 'Аргентина',
    code_alpha2: 'AR',
    active: true,
    phone_code: 54,
    popular: false,
  },
  {
    code_iso: 51,
    name_short_en: 'Armenia',
    name_short_ru: 'Армения',
    code_alpha2: 'AM',
    active: true,
    phone_code: 374,
    popular: false,
  },
  {
    code_iso: 31,
    name_short_en: 'Azerbaijan',
    name_short_ru: 'Азербайджан',
    code_alpha2: 'AZ',
    active: true,
    phone_code: 994,
    popular: false,
  },
  {
    code_iso: 44,
    name_short_en: 'Bahamas',
    name_short_ru: 'Багамы',
    code_alpha2: 'BS',
    active: true,
    phone_code: 1242,
    popular: false,
  },
  {
    code_iso: 50,
    name_short_en: 'Bangladesh',
    name_short_ru: 'Бангладеш',
    code_alpha2: 'BD',
    active: true,
    phone_code: 880,
    popular: false,
  },
  {
    code_iso: 112,
    name_short_en: 'Belarus',
    name_short_ru: 'Беларусь',
    code_alpha2: 'BY',
    active: true,
    phone_code: 375,
    popular: true,
  },
  {
    code_iso: 204,
    name_short_en: 'Benin',
    name_short_ru: 'Бенин',
    code_alpha2: 'BJ',
    active: true,
    phone_code: 229,
    popular: false,
  },
  {
    code_iso: 60,
    name_short_en: 'Bermuda',
    name_short_ru: 'Бермуды',
    code_alpha2: 'BM',
    active: true,
    phone_code: 1441,
    popular: false,
  },
  {
    code_iso: 68,
    name_short_en: 'Bolivia, plurinational state of',
    name_short_ru: 'Боливия',
    code_alpha2: 'BO',
    active: true,
    phone_code: 591,
    popular: false,
  },
  {
    code_iso: 70,
    name_short_en: 'Bosnia and Herzegovina',
    name_short_ru: 'Босния и Герцеговина',
    code_alpha2: 'BA',
    active: true,
    phone_code: 387,
    popular: false,
  },
  {
    code_iso: 76,
    name_short_en: 'Brazil',
    name_short_ru: 'Бразилия',
    code_alpha2: 'BR',
    active: true,
    phone_code: 55,
    popular: false,
  },
  {
    code_iso: 854,
    name_short_en: 'Burkina Faso',
    name_short_ru: 'Буркина‐Фасо',
    code_alpha2: 'BF',
    active: true,
    phone_code: 226,
    popular: false,
  },
  {
    code_iso: 120,
    name_short_en: 'Cameroon',
    name_short_ru: 'Камерун',
    code_alpha2: 'CM',
    active: true,
    phone_code: 237,
    popular: false,
  },
  {
    code_iso: 124,
    name_short_en: 'Canada',
    name_short_ru: 'Канада',
    code_alpha2: 'CA',
    active: true,
    phone_code: 1,
    popular: false,
  },
  {
    code_iso: 132,
    name_short_en: 'Cape Verde',
    name_short_ru: 'Кабо‐Верде',
    code_alpha2: 'CV',
    active: true,
    phone_code: 238,
    popular: false,
  },
  {
    code_iso: 136,
    name_short_en: 'Cayman Islands',
    name_short_ru: 'Каймановы о‐ва',
    code_alpha2: 'KY',
    active: true,
    phone_code: 1345,
    popular: false,
  },
  {
    code_iso: 148,
    name_short_en: 'Chad',
    name_short_ru: 'Чад',
    code_alpha2: 'TD',
    active: true,
    phone_code: 235,
    popular: false,
  },
  {
    code_iso: 152,
    name_short_en: 'Chile',
    name_short_ru: 'Чили',
    code_alpha2: 'CL',
    active: true,
    phone_code: 56,
    popular: false,
  },
  {
    code_iso: 162,
    name_short_en: 'Christmas Island',
    name_short_ru: 'Рождества о.',
    code_alpha2: 'CX',
    active: true,
    phone_code: 61,
    popular: false,
  },
  {
    code_iso: 174,
    name_short_en: 'Comoros',
    name_short_ru: 'Коморские о‐ва',
    code_alpha2: 'KM',
    active: true,
    phone_code: 269,
    popular: false,
  },
  {
    code_iso: 184,
    name_short_en: 'Cook Islands',
    name_short_ru: 'Кука о‐ва',
    code_alpha2: 'CK',
    active: true,
    phone_code: 682,
    popular: false,
  },
  {
    code_iso: 384,
    name_short_en: 'Cote d\'Ivoire',
    name_short_ru: 'Кот‐д\'Ивуар',
    code_alpha2: 'CI',
    active: true,
    phone_code: 225,
    popular: false,
  },
  {
    code_iso: 262,
    name_short_en: 'Djibouti',
    name_short_ru: 'Джибути',
    code_alpha2: 'DJ',
    active: true,
    phone_code: 253,
    popular: false,
  },
  {
    code_iso: 212,
    name_short_en: 'Dominica',
    name_short_ru: 'Доминика',
    code_alpha2: 'DM',
    active: true,
    phone_code: 1767,
    popular: false,
  },
  {
    code_iso: 214,
    name_short_en: 'Dominican Republic',
    name_short_ru: 'Доминиканская Республика',
    code_alpha2: 'DO',
    active: true,
    phone_code: 1809,
    popular: false,
  },
  {
    code_iso: 222,
    name_short_en: 'El Salvador',
    name_short_ru: 'Сальвадор',
    code_alpha2: 'SV',
    active: true,
    phone_code: 503,
    popular: false,
  },
  {
    code_iso: 232,
    name_short_en: 'Eritrea',
    name_short_ru: 'Эритрия',
    code_alpha2: 'ER',
    active: true,
    phone_code: 291,
    popular: false,
  },
  {
    code_iso: 234,
    name_short_en: 'Faroe Islands',
    name_short_ru: 'Фарерские о‐ва',
    code_alpha2: 'FO',
    active: true,
    phone_code: 298,
    popular: false,
  },
  {
    code_iso: 266,
    name_short_en: 'Gabon',
    name_short_ru: 'Габон',
    code_alpha2: 'GA',
    active: true,
    phone_code: 241,
    popular: false,
  },
  {
    code_iso: 270,
    name_short_en: 'Gambia',
    name_short_ru: 'Гамбия',
    code_alpha2: 'GM',
    active: true,
    phone_code: 220,
    popular: false,
  },
  {
    code_iso: 268,
    name_short_en: 'Georgia',
    name_short_ru: 'Грузия',
    code_alpha2: 'GE',
    active: true,
    phone_code: 995,
    popular: false,
  },
  {
    code_iso: 288,
    name_short_en: 'Ghana',
    name_short_ru: 'Гана',
    code_alpha2: 'GH',
    active: true,
    phone_code: 233,
    popular: false,
  },
  {
    code_iso: 292,
    name_short_en: 'Gibraltar',
    name_short_ru: 'Гибралтар',
    code_alpha2: 'GI',
    active: true,
    phone_code: 350,
    popular: false,
  },
  {
    code_iso: 304,
    name_short_en: 'Greenland',
    name_short_ru: 'Гренландия',
    code_alpha2: 'GL',
    active: true,
    phone_code: 299,
    popular: false,
  },
  {
    code_iso: 308,
    name_short_en: 'Grenada',
    name_short_ru: 'Гренада',
    code_alpha2: 'GD',
    active: true,
    phone_code: 1473,
    popular: false,
  },
  {
    code_iso: 320,
    name_short_en: 'Guatemala',
    name_short_ru: 'Гватемала',
    code_alpha2: 'GT',
    active: true,
    phone_code: 502,
    popular: false,
  },
  {
    code_iso: 324,
    name_short_en: 'Guinea',
    name_short_ru: 'Гвинея',
    code_alpha2: 'GN',
    active: true,
    phone_code: 224,
    popular: false,
  },
  {
    code_iso: 328,
    name_short_en: 'Guyana',
    name_short_ru: 'Гайана',
    code_alpha2: 'GY',
    active: true,
    phone_code: 592,
    popular: false,
  },
  {
    code_iso: 332,
    name_short_en: 'Haiti',
    name_short_ru: 'Гаити',
    code_alpha2: 'HT',
    active: true,
    phone_code: 509,
    popular: false,
  },
  {
    code_iso: 340,
    name_short_en: 'Honduras',
    name_short_ru: 'Гондурас',
    code_alpha2: 'HN',
    active: true,
    phone_code: 504,
    popular: false,
  },
  {
    code_iso: 344,
    name_short_en: 'Hong Kong',
    name_short_ru: 'Гонконг',
    code_alpha2: 'HK',
    active: true,
    phone_code: 852,
    popular: false,
  },
  {
    code_iso: 352,
    name_short_en: 'Iceland',
    name_short_ru: 'Исландия',
    code_alpha2: 'IS',
    active: true,
    phone_code: 354,
    popular: false,
  },
  {
    code_iso: 356,
    name_short_en: 'India',
    name_short_ru: 'Индия',
    code_alpha2: 'IN',
    active: true,
    phone_code: 91,
    popular: false,
  },
  {
    code_iso: 372,
    name_short_en: 'Ireland',
    name_short_ru: 'Ирландия',
    code_alpha2: 'IE',
    active: true,
    phone_code: 353,
    popular: false,
  },
  {
    code_iso: 388,
    name_short_en: 'Jamaica',
    name_short_ru: 'Ямайка',
    code_alpha2: 'JM',
    active: true,
    phone_code: 1876,
    popular: false,
  },
  {
    code_iso: 400,
    name_short_en: 'Jordan',
    name_short_ru: 'Иордания',
    code_alpha2: 'JO',
    active: true,
    phone_code: 962,
    popular: false,
  },
  {
    code_iso: 398,
    name_short_en: 'Kazakhstan',
    name_short_ru: 'Казахстан',
    code_alpha2: 'KZ',
    active: true,
    phone_code: 7,
    popular: true,
  },
  {
    code_iso: 404,
    name_short_en: 'Kenya',
    name_short_ru: 'Кения',
    code_alpha2: 'KE',
    active: true,
    phone_code: 254,
    popular: false,
  },
  {
    code_iso: 296,
    name_short_en: 'Kiribati',
    name_short_ru: 'Кирибати',
    code_alpha2: 'KI',
    active: true,
    phone_code: 686,
    popular: false,
  },
  {
    code_iso: 410,
    name_short_en: 'Korea, Republic of',
    name_short_ru: 'Республика Корея (Юж.Корея)',
    code_alpha2: 'KR',
    active: true,
    phone_code: 82,
    popular: false,
  },
  {
    code_iso: 417,
    name_short_en: 'Kyrgyzstan',
    name_short_ru: 'Киргизстан',
    code_alpha2: 'KG',
    active: true,
    phone_code: 996,
    popular: false,
  },
  {
    code_iso: 418,
    name_short_en: 'Lao People\'s Democratic Republic',
    name_short_ru: 'Лаос',
    code_alpha2: 'LA',
    active: true,
    phone_code: 856,
    popular: false,
  },
  {
    code_iso: 426,
    name_short_en: 'Lesotho',
    name_short_ru: 'Лесото',
    code_alpha2: 'LS',
    active: true,
    phone_code: 266,
    popular: false,
  },
  {
    code_iso: 430,
    name_short_en: 'Liberia',
    name_short_ru: 'Либерия',
    code_alpha2: 'LR',
    active: true,
    phone_code: 231,
    popular: false,
  },
  {
    code_iso: 438,
    name_short_en: 'Liechtenstein',
    name_short_ru: 'Лихтенштейн',
    code_alpha2: 'LI',
    active: true,
    phone_code: 423,
    popular: false,
  },
  {
    code_iso: 442,
    name_short_en: 'Luxembourg',
    name_short_ru: 'Люксембург',
    code_alpha2: 'LU',
    active: true,
    phone_code: 352,
    popular: false,
  },
  {
    code_iso: 446,
    name_short_en: 'Macao',
    name_short_ru: 'Макао',
    code_alpha2: 'MO',
    active: true,
    phone_code: 853,
    popular: false,
  },
  {
    code_iso: 807,
    name_short_en: 'Macedonia, The Former Yugoslav Republic Of',
    name_short_ru: 'Македония',
    code_alpha2: 'MK',
    active: true,
    phone_code: 389,
    popular: false,
  },
  {
    code_iso: 450,
    name_short_en: 'Madagascar',
    name_short_ru: 'Мадагаскар',
    code_alpha2: 'MG',
    active: true,
    phone_code: 261,
    popular: false,
  },
  {
    code_iso: 454,
    name_short_en: 'Malawi',
    name_short_ru: 'Малави',
    code_alpha2: 'MW',
    active: true,
    phone_code: 265,
    popular: false,
  },
  {
    code_iso: 458,
    name_short_en: 'Malaysia',
    name_short_ru: 'Малайзия',
    code_alpha2: 'MY',
    active: true,
    phone_code: 60,
    popular: false,
  },
  {
    code_iso: 462,
    name_short_en: 'Maldives',
    name_short_ru: 'Мальдивы',
    code_alpha2: 'MV',
    active: true,
    phone_code: 960,
    popular: false,
  },
  {
    code_iso: 584,
    name_short_en: 'Marshall Islands',
    name_short_ru: 'Маршалловы о‐ва',
    code_alpha2: 'MH',
    active: true,
    phone_code: 692,
    popular: false,
  },
  {
    code_iso: 480,
    name_short_en: 'Mauritius',
    name_short_ru: 'Маврикий',
    code_alpha2: 'MU',
    active: true,
    phone_code: 230,
    popular: false,
  },
  {
    code_iso: 484,
    name_short_en: 'Mexico',
    name_short_ru: 'Мексика',
    code_alpha2: 'MX',
    active: true,
    phone_code: 52,
    popular: false,
  },
  {
    code_iso: 583,
    name_short_en: 'Micronesia, Federated States of',
    name_short_ru: 'Микронезия',
    code_alpha2: 'FM',
    active: true,
    phone_code: 691,
    popular: false,
  },
  {
    code_iso: 498,
    name_short_en: 'Moldova',
    name_short_ru: 'Молдова',
    code_alpha2: 'MD',
    active: true,
    phone_code: 373,
    popular: false,
  },
  {
    code_iso: 492,
    name_short_en: 'Monaco',
    name_short_ru: 'Монако',
    code_alpha2: 'MC',
    active: true,
    phone_code: 377,
    popular: false,
  },
  {
    code_iso: 496,
    name_short_en: 'Mongolia',
    name_short_ru: 'Монголия',
    code_alpha2: 'MN',
    active: true,
    phone_code: 976,
    popular: false,
  },
  {
    code_iso: 336,
    name_short_en: 'Holy See (Vatican City State)',
    name_short_ru: 'Ватикан',
    code_alpha2: 'VA',
    active: true,
    phone_code: 379,
    popular: false,
  },
  {
    code_iso: 500,
    name_short_en: 'Montserrat',
    name_short_ru: 'Монсеррат о.',
    code_alpha2: 'MS',
    active: true,
    phone_code: 1664,
    popular: false,
  },
  {
    code_iso: 504,
    name_short_en: 'Morocco',
    name_short_ru: 'Марокко',
    code_alpha2: 'MA',
    active: true,
    phone_code: 212,
    popular: false,
  },
  {
    code_iso: 508,
    name_short_en: 'Mozambique',
    name_short_ru: 'Мозамбик',
    code_alpha2: 'MZ',
    active: true,
    phone_code: 258,
    popular: false,
  },
  {
    code_iso: 516,
    name_short_en: 'Namibia',
    name_short_ru: 'Намибия',
    code_alpha2: 'NA',
    active: true,
    phone_code: 264,
    popular: false,
  },
  {
    code_iso: 520,
    name_short_en: 'Nauru',
    name_short_ru: 'Науру',
    code_alpha2: 'NR',
    active: true,
    phone_code: 674,
    popular: false,
  },
  {
    code_iso: 528,
    name_short_en: 'Netherlands',
    name_short_ru: 'Нидерланды',
    code_alpha2: 'NL',
    active: true,
    phone_code: 31,
    popular: false,
  },
  {
    code_iso: 562,
    name_short_en: 'Niger',
    name_short_ru: 'Нигер',
    code_alpha2: 'NE',
    active: true,
    phone_code: 227,
    popular: false,
  },
  {
    code_iso: 566,
    name_short_en: 'Nigeria',
    name_short_ru: 'Нигерия',
    code_alpha2: 'NG',
    active: true,
    phone_code: 234,
    popular: false,
  },
  {
    code_iso: 570,
    name_short_en: 'Niue',
    name_short_ru: 'Ниуэ о.',
    code_alpha2: 'NU',
    active: true,
    phone_code: 683,
    popular: false,
  },
  {
    code_iso: 598,
    name_short_en: 'Papua New Guinea',
    name_short_ru: 'Папуа‐Новая Гвинея',
    code_alpha2: 'PG',
    active: true,
    phone_code: 675,
    popular: false,
  },
  {
    code_iso: 600,
    name_short_en: 'Paraguay',
    name_short_ru: 'Парагвай',
    code_alpha2: 'PY',
    active: true,
    phone_code: 595,
    popular: false,
  },
  {
    code_iso: 616,
    name_short_en: 'Poland',
    name_short_ru: 'Польша',
    code_alpha2: 'PL',
    active: true,
    phone_code: 48,
    popular: false,
  },
  {
    code_iso: 646,
    name_short_en: 'Rwanda',
    name_short_ru: 'Руанда',
    code_alpha2: 'RW',
    active: true,
    phone_code: 250,
    popular: false,
  },
  {
    code_iso: 659,
    name_short_en: 'Saint Kitts and Nevis',
    name_short_ru: 'Сент‐Кристофер и Невис',
    code_alpha2: 'KN',
    active: true,
    phone_code: 1869,
    popular: false,
  },
  {
    code_iso: 662,
    name_short_en: 'Saint Lucia',
    name_short_ru: 'Сент‐Люсия',
    code_alpha2: 'LC',
    active: true,
    phone_code: 1758,
    popular: false,
  },
  {
    code_iso: 666,
    name_short_en: 'Saint Pierre and Miquelon',
    name_short_ru: 'Сен‐Пьер и Микелон',
    code_alpha2: 'PM',
    active: true,
    phone_code: 508,
    popular: false,
  },
  {
    code_iso: 670,
    name_short_en: 'Saint Vincent and the Grenadines',
    name_short_ru: 'Сен‐Винсент и Гренадины',
    code_alpha2: 'VC',
    active: true,
    phone_code: 1784,
    popular: false,
  },
  {
    code_iso: 674,
    name_short_en: 'San Marino',
    name_short_ru: 'Сан Марино',
    code_alpha2: 'SM',
    active: true,
    phone_code: 378,
    popular: false,
  },
  {
    code_iso: 678,
    name_short_en: 'Sao Tome and Principe',
    name_short_ru: 'Сан‐Томе и Принсипи',
    code_alpha2: 'ST',
    active: true,
    phone_code: 239,
    popular: false,
  },
  {
    code_iso: 686,
    name_short_en: 'Senegal',
    name_short_ru: 'Сенегал',
    code_alpha2: 'SN',
    active: true,
    phone_code: 221,
    popular: false,
  },
  {
    code_iso: 690,
    name_short_en: 'Seychelles',
    name_short_ru: 'Сейшелы',
    code_alpha2: 'SC',
    active: true,
    phone_code: 248,
    popular: false,
  },
  {
    code_iso: 694,
    name_short_en: 'Sierra Leone',
    name_short_ru: 'Сьерра‐Леоне',
    code_alpha2: 'SL',
    active: true,
    phone_code: 232,
    popular: false,
  },
  {
    code_iso: 703,
    name_short_en: 'Slovakia',
    name_short_ru: 'Словакия',
    code_alpha2: 'SK',
    active: true,
    phone_code: 421,
    popular: false,
  },
  {
    code_iso: 90,
    name_short_en: 'Solomon Islands',
    name_short_ru: 'Соломоновы о‐ва',
    code_alpha2: 'SB',
    active: true,
    phone_code: 677,
    popular: false,
  },
  {
    code_iso: 740,
    name_short_en: 'Suriname',
    name_short_ru: 'Суринам',
    code_alpha2: 'SR',
    active: true,
    phone_code: 597,
    popular: false,
  },
  {
    code_iso: 744,
    name_short_en: 'Svalbard and Jan Mayen',
    name_short_ru: 'Шпицберген и Ян-Майен',
    code_alpha2: 'SJ',
    active: true,
    phone_code: 47,
    popular: false,
  },
  {
    code_iso: 748,
    name_short_en: 'Swaziland',
    name_short_ru: 'Свазиленд',
    code_alpha2: 'SZ',
    active: true,
    phone_code: 268,
    popular: false,
  },
  {
    code_iso: 762,
    name_short_en: 'Tajikistan',
    name_short_ru: 'Таджикистан',
    code_alpha2: 'TJ',
    active: true,
    phone_code: 992,
    popular: false,
  },
  {
    code_iso: 834,
    name_short_en: 'Tanzania, United Republic Of',
    name_short_ru: 'Танзания',
    code_alpha2: 'TZ',
    active: true,
    phone_code: 255,
    popular: false,
  },
  {
    code_iso: 626,
    name_short_en: 'Timor-Leste',
    name_short_ru: 'Восточный Тимор',
    code_alpha2: 'TL',
    active: true,
    phone_code: 670,
    popular: false,
  },
  {
    code_iso: 768,
    name_short_en: 'Togo',
    name_short_ru: 'Того',
    code_alpha2: 'TG',
    active: true,
    phone_code: 228,
    popular: false,
  },
  {
    code_iso: 776,
    name_short_en: 'Tonga',
    name_short_ru: 'Тонга',
    code_alpha2: 'TO',
    active: true,
    phone_code: 676,
    popular: false,
  },
  {
    code_iso: 788,
    name_short_en: 'Tunisia',
    name_short_ru: 'Тунис',
    code_alpha2: 'TN',
    active: true,
    phone_code: 216,
    popular: false,
  },
  {
    code_iso: 798,
    name_short_en: 'Tuvalu',
    name_short_ru: 'Тувалу',
    code_alpha2: 'TV',
    active: true,
    phone_code: 688,
    popular: false,
  },
  {
    code_iso: 858,
    name_short_en: 'Uruguay',
    name_short_ru: 'Уругвай',
    code_alpha2: 'UY',
    active: true,
    phone_code: 598,
    popular: false,
  },
  {
    code_iso: 860,
    name_short_en: 'Uzbekistan',
    name_short_ru: 'Узбекистан',
    code_alpha2: 'UZ',
    active: true,
    phone_code: 998,
    popular: false,
  },
  {
    code_iso: 548,
    name_short_en: 'Vanuatu',
    name_short_ru: 'Вануату',
    code_alpha2: 'VU',
    active: true,
    phone_code: 678,
    popular: false,
  },
  {
    code_iso: 732,
    name_short_en: 'Western Sahara',
    name_short_ru: 'Западная Сахара',
    code_alpha2: 'EH',
    active: true,
    phone_code: 212,
    popular: false,
  },
  {
    code_iso: 894,
    name_short_en: 'Zambia',
    name_short_ru: 'Замбия',
    code_alpha2: 'ZM',
    active: true,
    phone_code: 260,
    popular: false,
  },
  {
    code_iso: 48,
    name_short_en: 'Bahrain',
    name_short_ru: 'Бахрейн',
    code_alpha2: 'BH',
    active: false,
    phone_code: 973,
    popular: false,
  },
  {
    code_iso: 56,
    name_short_en: 'Belgium',
    name_short_ru: 'Бельгия',
    code_alpha2: 'BE',
    active: false,
    phone_code: 32,
    popular: false,
  },
  {
    code_iso: 84,
    name_short_en: 'Belize',
    name_short_ru: 'Белиз',
    code_alpha2: 'BZ',
    active: false,
    phone_code: 501,
    popular: false,
  },
  {
    code_iso: 64,
    name_short_en: 'Bhutan',
    name_short_ru: 'Бутан',
    code_alpha2: 'BT',
    active: false,
    phone_code: 975,
    popular: false,
  },
  {
    code_iso: 96,
    name_short_en: 'Brunei Darussalam',
    name_short_ru: 'Бруней Дарассалам',
    code_alpha2: 'BN',
    active: false,
    phone_code: 673,
    popular: false,
  },
  {
    code_iso: 188,
    name_short_en: 'Costa Rica',
    name_short_ru: 'Коста‐Рика',
    code_alpha2: 'CR',
    active: false,
    phone_code: 506,
    popular: false,
  },
  {
    code_iso: 191,
    name_short_en: 'Croatia',
    name_short_ru: 'Хорватия',
    code_alpha2: 'HR',
    active: false,
    phone_code: 385,
    popular: false,
  },
  {
    code_iso: 203,
    name_short_en: 'Czech Republic',
    name_short_ru: 'Чехия',
    code_alpha2: 'CZ',
    active: false,
    phone_code: 420,
    popular: false,
  },
  {
    code_iso: 208,
    name_short_en: 'Denmark',
    name_short_ru: 'Дания',
    code_alpha2: 'DK',
    active: false,
    phone_code: 45,
    popular: false,
  },
  {
    code_iso: 818,
    name_short_en: 'Egypt',
    name_short_ru: 'Египет',
    code_alpha2: 'EG',
    active: false,
    phone_code: 20,
    popular: false,
  },
  {
    code_iso: 226,
    name_short_en: 'Equatorial Guinea',
    name_short_ru: 'Экваториальная Гвинея',
    code_alpha2: 'GQ',
    active: false,
    phone_code: 240,
    popular: false,
  },
  {
    code_iso: 231,
    name_short_en: 'Ethiopia',
    name_short_ru: 'Эфиопия',
    code_alpha2: 'ET',
    active: false,
    phone_code: 251,
    popular: false,
  },
  {
    code_iso: 254,
    name_short_en: 'French Guiana',
    name_short_ru: 'Французская Гвиана (FR)',
    code_alpha2: 'GF',
    active: false,
    phone_code: 594,
    popular: false,
  },
  {
    code_iso: 360,
    name_short_en: 'Indonesia',
    name_short_ru: 'Индонезия',
    code_alpha2: 'ID',
    active: false,
    phone_code: 62,
    popular: false,
  },
  {
    code_iso: 376,
    name_short_en: 'Israel',
    name_short_ru: 'Израиль',
    code_alpha2: 'IL',
    active: false,
    phone_code: 972,
    popular: false,
  },
  {
    code_iso: 392,
    name_short_en: 'Japan',
    name_short_ru: 'Япония',
    code_alpha2: 'JP',
    active: false,
    phone_code: 81,
    popular: false,
  },
  {
    code_iso: 414,
    name_short_en: 'Kuwait',
    name_short_ru: 'Кувейт',
    code_alpha2: 'KW',
    active: false,
    phone_code: 965,
    popular: false,
  },
  {
    code_iso: 478,
    name_short_en: 'Mauritania',
    name_short_ru: 'Мавритания',
    code_alpha2: 'MR',
    active: false,
    phone_code: 222,
    popular: false,
  },
  {
    code_iso: 524,
    name_short_en: 'Nepal',
    name_short_ru: 'Непал',
    code_alpha2: 'NP',
    active: false,
    phone_code: 977,
    popular: false,
  },
  {
    code_iso: 643,
    name_short_en: 'Russian Federation',
    name_short_ru: 'Россия',
    code_alpha2: 'RU',
    active: true,
    phone_code: 7,
    popular: true,
  },
  {
    code_iso: 756,
    name_short_en: 'Switzerland',
    name_short_ru: 'Швейцария',
    code_alpha2: 'CH',
    active: false,
    phone_code: 41,
    popular: false,
  },
  {
    code_iso: 554,
    name_short_en: 'New Zealand',
    name_short_ru: 'Новая Зеландия',
    code_alpha2: 'NZ',
    active: false,
    phone_code: 64,
    popular: false,
  },
  {
    code_iso: 578,
    name_short_en: 'Norway',
    name_short_ru: 'Норвегия',
    code_alpha2: 'NO',
    active: false,
    phone_code: 47,
    popular: false,
  },
  {
    code_iso: 512,
    name_short_en: 'Oman',
    name_short_ru: 'Оман',
    code_alpha2: 'OM',
    active: false,
    phone_code: 968,
    popular: false,
  },
  {
    code_iso: 591,
    name_short_en: 'Panama',
    name_short_ru: 'Панама',
    code_alpha2: 'PA',
    active: false,
    phone_code: 507,
    popular: false,
  },
  {
    code_iso: 604,
    name_short_en: 'Peru',
    name_short_ru: 'Перу',
    code_alpha2: 'PE',
    active: false,
    phone_code: 51,
    popular: false,
  },
  {
    code_iso: 634,
    name_short_en: 'Qatar',
    name_short_ru: 'Катар',
    code_alpha2: 'QA',
    active: false,
    phone_code: 974,
    popular: false,
  },
  {
    code_iso: 682,
    name_short_en: 'Saudi Arabia',
    name_short_ru: 'Саудовская Аравия',
    code_alpha2: 'SA',
    active: false,
    phone_code: 966,
    popular: false,
  },
  {
    code_iso: 702,
    name_short_en: 'Singapore',
    name_short_ru: 'Сингапур',
    code_alpha2: 'SG',
    active: false,
    phone_code: 65,
    popular: false,
  },
  {
    code_iso: 705,
    name_short_en: 'Slovenia',
    name_short_ru: 'Словения',
    code_alpha2: 'SI',
    active: false,
    phone_code: 386,
    popular: false,
  },
  {
    code_iso: 710,
    name_short_en: 'South Africa',
    name_short_ru: 'Южная Африка',
    code_alpha2: 'ZA',
    active: false,
    phone_code: 27,
    popular: false,
  },
  {
    code_iso: 144,
    name_short_en: 'Sri Lanka',
    name_short_ru: 'Шри‐Ланка',
    code_alpha2: 'LK',
    active: false,
    phone_code: 94,
    popular: false,
  },
  {
    code_iso: 764,
    name_short_en: 'Thailand',
    name_short_ru: 'Таиланд',
    code_alpha2: 'TH',
    active: false,
    phone_code: 66,
    popular: false,
  },
  {
    code_iso: 795,
    name_short_en: 'Turkmenistan',
    name_short_ru: 'Туркменистан',
    code_alpha2: 'TM',
    active: false,
    phone_code: 7370,
    popular: false,
  },
  {
    code_iso: 796,
    name_short_en: 'Turks and Caicos Islands',
    name_short_ru: 'Теркс и Кайкос о‐ва (GB)',
    code_alpha2: 'TC',
    active: false,
    phone_code: 1649,
    popular: false,
  },
  {
    code_iso: 784,
    name_short_en: 'United Arab Emirates',
    name_short_ru: 'ОАЭ',
    code_alpha2: 'AE',
    active: false,
    phone_code: 971,
    popular: false,
  },
  {
    code_iso: 581,
    name_short_en: 'United States Minor Outlying Islands',
    name_short_ru: 'Внешние малые острова США',
    code_alpha2: 'UM',
    active: false,
    phone_code: 1,
    popular: false,
  },
  {
    code_iso: 704,
    name_short_en: 'Vietnam',
    name_short_ru: 'Вьетнам',
    code_alpha2: 'VN',
    active: false,
    phone_code: 84,
    popular: false,
  },
  {
    code_iso: 832,
    name_short_en: 'Jersey',
    name_short_ru: 'Джерси',
    code_alpha2: 'JE',
    active: true,
    phone_code: 44,
    popular: false,
  },
  {
    code_iso: 833,
    name_short_en: 'Isle of Man',
    name_short_ru: 'Остров Мэн',
    code_alpha2: 'IM',
    active: true,
    phone_code: 44,
    popular: false,
  },
  {
    code_iso: 663,
    name_short_en: 'Collectivity of Saint Martin',
    name_short_ru: 'Сен-Мартен',
    code_alpha2: 'MF',
    active: true,
    phone_code: 590,
    popular: false,
  },
  {
    code_iso: 688,
    name_short_en: 'Serbia',
    name_short_ru: 'Сербия',
    code_alpha2: 'RS',
    active: true,
    phone_code: 381,
    popular: false,
  },
  {
    code_iso: 831,
    name_short_en: 'Bailiwick of Guernsey',
    name_short_ru: 'Гернси',
    code_alpha2: 'GG',
    active: true,
    phone_code: 44,
    popular: false,
  },
  {
    code_iso: 276,
    name_short_en: 'Germany',
    name_short_ru: 'Германия',
    code_alpha2: 'DE',
    active: true,
    phone_code: 49,
    popular: false,
  },
];

export const GAME_CARD_DISPLAY_POLICY: { [key: string]: string } = { locked: 'LOCKED' };

export const CURRENCIES_SYMBOL = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
  GEL: '₾',
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const FORMAT_DATE = {
  ISO_DATE: 'YYYY-MM-DD',
  DOT_DATE: 'DD.MM.YYYY',
  TIME_AND_DATE: 'HH:mm DD MMMM YYYY',
};

export const APP_RUN_MODES = {
  development: 'development',
  production: 'production',
};

export const BONUS_ACTIVATION_CACHE_STORAGE_NAME = 'j-bonus-activation-cache';

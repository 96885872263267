import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import clean_45login_45referrer_45path_45global from "/usr/src/app/middleware/cleanLoginReferrerPath.global.ts";
import free_45spins_45notification_45global from "/usr/src/app/middleware/freeSpinsNotification.global.ts";
import handle_45page_45url_45queries_45global from "/usr/src/app/middleware/handlePageUrlQueries.global.ts";
import initialize_45user_45global from "/usr/src/app/middleware/initializeUser.global.ts";
import maintenance_45global from "/usr/src/app/middleware/maintenance.global.ts";
import page_45loader_45global from "/usr/src/app/middleware/pageLoader.global.ts";
import redirect_45if_45profile_45filled_45global from "/usr/src/app/middleware/redirectIfProfileFilled.global.ts";
import send_45referral_45link_45global from "/usr/src/app/middleware/sendReferralLink.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clean_45login_45referrer_45path_45global,
  free_45spins_45notification_45global,
  handle_45page_45url_45queries_45global,
  initialize_45user_45global,
  maintenance_45global,
  page_45loader_45global,
  redirect_45if_45profile_45filled_45global,
  send_45referral_45link_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  cabinet: () => import("/usr/src/app/middleware/cabinet.ts"),
  cashier: () => import("/usr/src/app/middleware/cashier.ts"),
  "confirmation-email": () => import("/usr/src/app/middleware/confirmationEmail.ts"),
  "ensure-authenticated": () => import("/usr/src/app/middleware/ensureAuthenticated.ts"),
  game: () => import("/usr/src/app/middleware/game.ts"),
  games: () => import("/usr/src/app/middleware/games.ts"),
  "info-messages": () => import("/usr/src/app/middleware/infoMessages.ts"),
  "new-details": () => import("/usr/src/app/middleware/newDetails.ts"),
  "promo-details": () => import("/usr/src/app/middleware/promoDetails.ts"),
  "redirect-if-authenticated": () => import("/usr/src/app/middleware/redirectIfAuthenticated.ts"),
  "redirect-if-user-blocked-lite": () => import("/usr/src/app/middleware/redirectIfUserBlockedLite.ts"),
  "tournament-details": () => import("/usr/src/app/middleware/tournamentDetails.ts"),
  "tournaments-type": () => import("/usr/src/app/middleware/tournamentsType.ts")
}
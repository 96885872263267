import type {
  FetchOptions,
  HTTPRequestMethods,
} from '~/types/composables/useMyFetch';
import { useAuthStore } from '~/stores/auth';

export const authStoreLogout = async () => {
  const authStore = useAuthStore();

  await authStore.logout();
};
export const useMyFetch = () => {
  const makeRequest = (method: HTTPRequestMethods) => async (endpoint: string, opts?: FetchOptions) => {
    let statusCode: number | undefined = undefined;
    let responseData: Response | undefined = undefined;
    const useFetchOptions = {
      server: false,
      ...(opts || {}),
    };
    const {
      data,
      error,
    } = await useFetch(endpoint, {
      baseURL: useBaseAppUrl().api,
      method,
      ...useFetchOptions,
      onResponse({ response }) {
        statusCode = response.status;
        responseData = response._data;
      },
    });

    if (statusCode && !(statusCode >= 200 && statusCode < 300)) {
      if (statusCode === 401) {
        throw showError({
          statusCode,
          data: error.value?.data || responseData,
        });
      } else {
        throw {
          statusCode,
          data: error.value?.data || responseData,
        };
      }
    }

    return {
      statusCode,
      data: toRaw(data.value),
    };
  };

  return {
    post: makeRequest('post'),
    get: makeRequest('get'),
    put: makeRequest('put'),
    delete: makeRequest('delete'),
  };
};

<template>
  <div class="j-page-loader">
    <img
      class="j-page-loader__icon"
      :lazy-src="useImgSrc(images['page_loader'])"
      :src="useImgSrc(images['page_loader'])"
      alt="Preloader..."
    >
  </div>
</template>

<script setup lang="ts">
const images = useAssetsImages();
</script>

<style scoped lang="scss">
.j-page-loader {
  background: $j-bg-main;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.j-page-loader__icon {
  @include spinning(2s);
}
</style>

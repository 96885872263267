<template>
  <v-item-group class="j-notifications-wrapper">
    <v-item
      v-for="(notification) of getNotifications"
      :key="notification.id"
    >
      <j-notifications-parts-notification-item
        :id="notification.id"
        :model-value="notification.showing"
        :timeout="notification.timeout"
        :icon="notification.icon"
        :title="notification.dictionaryKeyTitle ? $t(notification.dictionaryKeyTitle) : ''"
        :text="$t(notification.dictionaryKeyText, notification.dictionaryKeyTextProps || {})"
        :vertical="notification.vertical"
        :width="notification.width"
        :buttons="notification.buttons"
        :color="notification.color"
        :is-cookie="notification.isCookie"
        :is-free-spins="notification.isFreeSpins"
        :is-email-conformation="notification.isEmailConformation"
        @read-cookie="readCookie"
        @game-link="gameLink"
        @remove="removeNotification(notification)"
        @close="closeNotification(notification)"
      />
    </v-item>
  </v-item-group>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useGlobalsStore } from '~/stores/globals';
import type { NotificationButtonAction } from '~/types/components/General/Notifications';

const router = useRouter();
const globalsStore = useGlobalsStore();
const {
  removeNotification,
  closeNotification,
} = globalsStore;
const { getNotifications } = storeToRefs(globalsStore);
const readCookie = () => {
  // TODO: Check all local storage usage and refactor code. Use useLocalStorageObject only for objects
  useLocalStorageObject.set('j-cookie-policy', true);
};
const gameLink = (gameData: NotificationButtonAction) => {
  router.push(`/game/${gameData.gamePrettyName}/real`);
};
</script>
<style lang="scss" scoped>
.j-notifications-wrapper {
  position: fixed;
  top: 82px;
  right: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 1280px) {
    top: 90px;
  }
}
</style>

export const API_PREFIX = {
  default: '/v1',
  playerProfile: '/player-info/v1',
  player: '/v1/player',
  playerVerification: '/player-verification',
  playerGamesHistory: '/player-games-history/v2',
  promotions: '/v1/promotions/player',
  account: '/v1/account',
  lobby: '/lobby',
  news: '/v1/news',
  transactionsHistory: '/transactions-history/v2',
  password: '/v1/password',
  tournaments: '/v3/rest/tournaments',
  tournamentsActual: '/v2/rest/tournaments/actual',
  game: '/v1/game',
};
